import { useEffect, useState, useRef } from 'react';
import ScreenFilter from './component/ScreenFilter';
import Router from './Router';
import './default.sass';
import Profile from './route/Profile';
import Projects from './route/Projects';
import Link from './route/Link';
import Contact from './route/Contact';

const routes = [
    { id: '01', route: 'profile', component: Profile },
    { id: '02', route: 'links', component: Link },
    { id: '03', route: 'contact', component: Contact },
];

// 스크롤로 채널 변경
// 채널 변경시 컴포넌트도 변경
const App = () => {
    let isRouting = false;
    const changeTime = 300;
    const [change, _setChange] = useState(isRouting);
    const [channel, _setChannel] = useState(0);
    const [router, _setRouter] = useState(routes[channel]);
    let touchStart = 0;
    let touchEnd = 0;

    const channelRef = useRef(channel);

    const setChannel = (data) => {
        if (data < 0) data = routes.length - 1;
        if (data >= routes.length) data = 0;
        channelRef.current = data;
        _setChannel(data);
        _setRouter(routes[data]);
    };

    const checkRoutingAnimation = (bool) => {
        isRouting = bool;
        _setChange(isRouting);
    };

    const changeChannel = (isUp) => {
        checkRoutingAnimation(true);
        setChannel(isUp ? channelRef.current + 1 : channelRef.current - 1);
        setTimeout(() => checkRoutingAnimation(false), changeTime);
    };

    const wheelListener = (e) => {
        if (isRouting) return;
        changeChannel(e.deltaY > 0);
    };

    const touchStartListener = (e) => {
        const { touches } = e;
        const { pageY } = touches[0];
        touchStart = pageY;
        touchEnd = pageY;
    };
    const touchMoveListener = (e) => {
        const { touches } = e;
        const { pageY } = touches[0];
        touchEnd = pageY;
    };
    const touchEndListener = (e) => {
        const dist = touchEnd - touchStart;
        const min = window.innerHeight / 12;

        if (Math.abs(dist) < min) return;

        changeChannel(dist > 0);
    };

    const getCurrentChannel = () => {
        const hash = window.location.hash.replace('#', '');
        return routes.findIndex(({ route }) => route === hash);
    };

    useEffect(() => {
        document.addEventListener('wheel', wheelListener);
        document.addEventListener('touchstart', touchStartListener);
        document.addEventListener('touchmove', touchMoveListener);
        document.addEventListener('touchend', touchEndListener);
    }, []);
    return (
        <>
            <ScreenFilter
                router={router}
                isRouting={isRouting}
                change={change}
            ></ScreenFilter>
            <Router
                router={router}
                setChannel={setChannel}
                getCurrentChannel={getCurrentChannel}
            ></Router>
        </>
    );
};

export default App;
