import { useEffect, useRef } from 'react';
import './style.sass';
import { mixKeyframes } from '../../util/animation';

const opacityKeyframe = {
    0: { opacity: 0.8 },
    10: { opacity: 0.564 },
    20: { opacity: 0.789 },
    30: { opacity: 0.856 },
    40: { opacity: 0.753 },
    50: { opacity: 0.654 },
    60: { opacity: 0.483 },
    70: { opacity: 0.582 },
    80: { opacity: 0.952 },
    90: { opacity: 0.742 },
    100: { opacity: 0.8 },
};
const bgPositionKeyframe = {
    0: { backgroundPosition: '0px 0px' },
    20: { backgroundPosition: '0px 5px' },
    30: { backgroundPosition: '0px 0px' },
    100: { backgroundPosition: '0px 10px' },
};
const changePoisitionKeyFrame = {
    0: { backgroundPosition: '0px 0px' },
    10: { backgroundPosition: '8px 0px' },
    20: { backgroundPosition: '0px 0px' },
    30: { backgroundPosition: '0px 0px' },
    40: { backgroundPosition: '0px 0px' },
    50: { backgroundPosition: '0px 0px' },
    60: { backgroundPosition: '0px 0px' },
    70: { backgroundPosition: '0px 0px' },
    80: { backgroundPosition: '0px 0px' },
    90: { backgroundPosition: '0px 0px' },
    100: { backgroundPosition: '0px 0px' },
};

const random = (max) => Math.floor(Math.random() * max);
const randomPositionKeyFrame = (div) => {
    const keyframe = {};
    for (let i = 0; i < div; i++) {
        keyframe[i * div] = {
            backgroundPosition: `${random(32)}px ${random(16)}px`,
        };
    }
    return keyframe;
};

const crtScreenKeyframe = mixKeyframes(opacityKeyframe, bgPositionKeyframe);
const changeGrayKeyframe = mixKeyframes(randomPositionKeyFrame(10));
const changeBlackKeyframe = mixKeyframes(randomPositionKeyFrame(10));

const ScreenFilter = ({ router, change }) => {
    const noiseRef = useRef(null);
    const changeGrayRef = useRef(null);
    const changeBlackRef = useRef(null);

    useEffect(() => {
        noiseRef.current.animate(crtScreenKeyframe, {
            duration: 1000,
            iterations: Infinity,
        });
    }, []);

    useEffect(() => {
        if (!change) return;
        changeGrayRef.current.animate(changeGrayKeyframe, {
            duration: 800,
            iterations: Infinity,
        });
        changeBlackRef.current.animate(changeBlackKeyframe, {
            duration: 400,
            iterations: Infinity,
        });
    });

    return (
        <>
            {change ? (
                <>
                    <div className='static__effect--change-channel static__effect--change-channel-white'></div>
                    <div
                        ref={changeGrayRef}
                        className='static__effect--change-channel static__effect--change-channel-gray'
                    ></div>
                    <div
                        ref={changeBlackRef}
                        className='static__effect--change-channel static__effect--change-channel-black'
                    ></div>
                </>
            ) : (
                <div className='static__ui--channel'>
                    <div>{router.id}</div>
                    <div>{router.route}</div>
                </div>
            )}
            <div ref={noiseRef} className='static__effect--noise-filter'></div>
        </>
    );
};

export default ScreenFilter;
