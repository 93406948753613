export const mixKeyframes = (...keyframes) => {
    const mixedKeyframe = [];
    for (let i = 0; i <= 100; i++) {
        const currKey = {};
        for (const key of keyframes) {
            if (key[i]) Object.assign(currKey, key[i]);
        }
        if (!Object.keys(currKey).length) continue;
        Object.assign(currKey, { offset: i / 100 });
        mixedKeyframe.push(currKey);
    }
    return mixedKeyframe;
};
