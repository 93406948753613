import { lightUpStars, blinkStars } from './stars';
import { ctxHOC, drawLine, getHV } from './utils';
import { cvVars } from './utils/static';

const drawVerticalLines = (ctx, duration, time) => {
    const [H, V] = getHV();

    const start = V / 2;
    const end = V;
    const halfH = H / 2;

    const topStep = 40;
    const botStep = 100;

    const drawRate = time > duration ? 1 : time / duration;

    drawLine(ctx, halfH, start, halfH, end, drawRate);
    for (let i = 1; i * topStep < halfH; i++) {
        drawLine(
            ctx,
            halfH + topStep * i,
            start,
            halfH + botStep * i * Math.pow(1.1, i),
            end,
            drawRate
        );
        drawLine(
            ctx,
            halfH - topStep * i,
            start,
            halfH - botStep * i * Math.pow(1.1, i),
            end,
            drawRate
        );
    }
};

const drawHorizontalLine = (ctx, duration, time, lines = 10) => {
    const [H, V] = getHV();

    const start = V / 2;
    const end = V;
    const totalDist = end - start;
    const mainX = (time / duration) % 1;
    const yStep = duration / lines;

    for (let i = 0; i < lines; i++) {
        const yRate = (time + yStep * i) / duration;
        const currX = yRate % 1;
        // 시간순 애니메이션을 위해
        if (time < duration && mainX < currX) continue;
        const currY = start + totalDist * Math.pow(currX, 2);
        drawLine(ctx, 0, currY, H, currY);
    }
};

const drawHorizon = (ctx, duration, time) => {
    const [H, V] = getHV();
    const halfH = H / 2;
    const halfV = V / 2;
    const rate = time < duration ? time / duration : 1;

    drawLine(ctx, halfH, halfV, 0, halfV, rate);
    drawLine(ctx, halfH, halfV, H, halfV, rate);
};

export const Ground = (ctx, ms, delta, resize) => {
    ctx.shadowBlur = 10;
    ctx.shadowColor = cvVars.color.ground;

    const phases = {
        loading: 250,
        openHorizon: 2000,
        openVerticalLines: 2000,
        openHorizontalLines: 3000,
    };
    let threshold = 0;

    // ■■ PHASE 1 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    threshold += phases.loading;
    if (ms < threshold) return;
    // ■■ PHASE 2 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 지평선 애니메이션
    drawHorizon(ctx, phases.openHorizon, ms - threshold);
    threshold += phases.openHorizon;
    // 지평선과 함께 떠오르는 별
    if (ms < threshold)
        return lightUpStars(
            ctx,
            phases.openHorizon,
            ms - phases.loading,
            resize
        );
    // ■■ PHASE 3 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 별 반짝이는 애니메이션
    blinkStars(ctx, phases.openVerticalLines, ms - threshold, resize);
    // 수직선 애니메이션
    drawVerticalLines(ctx, phases.openVerticalLines, ms - threshold);
    threshold += phases.openVerticalLines;
    if (ms < threshold) return;
    // ■■ PHASE 4 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 가로선 애니메이션
    drawHorizontalLine(ctx, phases.openHorizontalLines, ms - threshold);
};

export default ctxHOC(Ground);
