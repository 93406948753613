import logoTwitter from '../../static/images/logo_twitter.png';
import logoNotion from '../../static/images/logo_notion.png';
import logoGithub from '../../static/images/logo_github.png';
import './style.sass';
import Canvas from '../../component/Canvas';
import { Ground } from '../../component/Canvas/game/ground';

const Links = () => {
    return (
        <div className='comp__common'>
            <div className='project__background'></div>
            <div className='link__section'>
                <Link
                    name='blog'
                    href='https://dorage.notion.site/cfa014f546a2486da505dc7cbf3b6c84'
                    src={logoNotion}
                    alt='logoNotion'
                />
                <Link
                    name='github'
                    href='https://github.com/dorage'
                    src={logoGithub}
                    alt='logoGithub'
                />
                <Link
                    name='twitter'
                    href='https://twitter.com/DORAGE_SAN'
                    src={logoTwitter}
                    alt='logoTwitter'
                />
            </div>
        </div>
    );
};

const Link = ({ name, href, src, alt }) => (
    <a className='link__a' href={href} target='_blank' rel='noreferrer'>
        <h5>{name}</h5>
        <img className='link__img--thumb' src={src} alt={alt}></img>
    </a>
);

export default Links;
