import './style.sass';
import Canvas from '../../component/Canvas';
import mail from '../../component/Canvas/game/mail';
import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

emailjs.init('cQeo3lPIjbGbI5QTv');

const Contact = () => {
    const [hasSent, setSent] = useState(false);
    const [wink, setWink] = useState(true);

    const clearForm = (inputs) => {
        [...inputs].forEach((el) => (el.value = ''));
    };
    const collectValues = (inputs) =>
        inputs.reduce((a, c) => {
            a[c.name] = c.value;
            return a;
        }, {});

    const onSubmit = (e) => {
        e.preventDefault();
        const inputs = [...e.currentTarget.querySelectorAll('input, textarea')];
        const message = collectValues(inputs);

        try {
            console.log(message);
            emailjs.send('service_rucjvfs', 'template_3kbw9t9', message).then(
                function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                },
                function (error) {
                    console.log('FAILED...', error);
                }
            );

            clearForm(inputs);
            setSent(true);
        } catch (err) {}
    };

    useEffect(() => {
        setTimeout(() => setWink(!wink), 800);
    }, [hasSent, wink]);

    return (
        <div className='comp__common'>
            <Canvas components={[mail]}></Canvas>
            <section>
                {hasSent ? (
                    <h1 className='contact__sentok'>
                        OK! SENT! {wink ? "'_'" : "'_^"}
                    </h1>
                ) : (
                    <>
                        <h1>Send Me Anything!</h1>
                        <br></br>
                        <form
                            className='sect__contact--form'
                            onSubmit={onSubmit}
                        >
                            <input
                                name='name'
                                type='text'
                                className='form__input'
                                placeholder='name'
                                required
                            ></input>
                            <input
                                name='contact'
                                type='text'
                                className='form__input'
                                placeholder='contact'
                                required
                            ></input>
                            <textarea
                                name='message'
                                type='text'
                                className='form__input'
                                placeholder='...(~100 byte)'
                                maxLength={100}
                                required
                            ></textarea>
                            <button className='form__submit'>Send</button>
                        </form>
                    </>
                )}
            </section>
        </div>
    );
};

export default Contact;
