export default [
  {
    "id": "46f31ac0-69f6-49a5-9ed5-8e3fa82b4231",
    "name": "삼꾸미집",
    "stacks": [
      "HTML",
      "Javascript",
      "CSS",
      "CloudFlare Pages",
      "AWS Lambda",
      "Naver SENS API"
    ],
    "year": "2022"
  },
  {
    "id": "a9a1fb00-f92f-4a0e-a6d0-06c2a61bea12",
    "name": "PIWIMI",
    "stacks": [
      "Javascript",
      "PostgreSQL",
      "PUG",
      "VULTR"
    ],
    "year": "2021"
  },
  {
    "id": "d24f1902-4dbe-4efc-a6fb-5f9e4f3688cc",
    "name": "넷플릭스 스타일 슬라이더",
    "stacks": [
      "React",
      "Javascript",
      "Netlify"
    ],
    "year": "2020"
  },
  {
    "id": "286763e0-35c5-4f15-86ff-cb618ab92816",
    "name": "소개팅연구소",
    "stacks": [
      "Flutter",
      "Dart",
      "React",
      "Javascript"
    ],
    "year": "2020"
  },
  {
    "id": "12d2e83b-60ef-496b-9812-b29ab8d9a9af",
    "name": "Night Drive",
    "stacks": [
      "React",
      "Javascript",
      "PIXI.JS"
    ],
    "year": "2020"
  },
  {
    "id": "d51f8261-6f69-4884-8605-36c41a003b9d",
    "name": "벚꽃클리커",
    "stacks": [
      "Unity"
    ],
    "year": "2019"
  },
  {
    "id": "091c02c3-2bcc-4a8e-9f19-82364f422375",
    "name": "FAW (Fucking Awesome Weather)",
    "stacks": [
      "React",
      "Javascript",
      "Netlify"
    ],
    "year": "2019"
  },
  {
    "id": "63e69afa-2d3d-4c42-ade5-074a74429d10",
    "name": "CATLOVER",
    "stacks": [
      "React",
      "ExpressJS",
      "Javascript",
      "Python",
      "MongoDB",
      "AWS EC2"
    ],
    "year": "2019"
  }
];