import { useEffect, useState } from 'react';

const Router = ({ router, setChannel, getCurrentChannel }) => {
    const [first, setFirst] = useState(true);

    useEffect(() => {
        const idx = getCurrentChannel();
        if (idx !== -1) setChannel(idx);
        setFirst(false);
    }, []);

    useEffect(() => {
        if (first) return;
        if (router.route) window.location.hash = `${router.route}`;
    }, [router]);

    return <router.component></router.component>;
};

export default Router;
