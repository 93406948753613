import { cvVars } from './static';

export const clamp = (n, min, max) => {
    return Math.min(Math.max(n, min), max);
};

export const random = (min, max) => {
    return ((Math.random() * max) % (max - min)) + min;
};
export const randomRate = (decimal) => {
    const power = Math.pow(10, decimal);
    return Math.floor(Math.random() * power) / power;
};

export const getHV = () => [window.innerWidth, window.innerHeight];

export const ctxHOC =
    (fn) =>
    (...keys) =>
        fn(...keys);

export const drawLine = (
    ctx,
    x1,
    y1,
    x2,
    y2,
    duration = 1,
    color = cvVars.color.ground,
    width = 2
) => {
    duration = clamp(duration, 0, 1);
    if (duration !== 1) {
        x2 = x1 + (x2 - x1) * duration;
        y2 = y1 + (y2 - y1) * duration;
    }

    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.lineTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.closePath();
    ctx.stroke();
};

export const drawFillRect = (ctx, x1, y1, width, height) => {
    ctx.save();
    ctx.fillRect(x1, y1, width, height);
    ctx.restore();
};
export const drawStrokeRect = (ctx, x1, y1, width, height) => {
    ctx.save();
    ctx.strokeRect(x1, y1, width, height);
    ctx.restore();
};

export const drawImage = () => {};
