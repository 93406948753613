import { useEffect } from 'react';
import ground from './game/ground';
import { setMouse } from './game/utils/mouse';
import './style.sass';

const resizeCanvas = (ctx) => {
    ctx.canvas.width = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
};

const initializeCanvas = (ctx, resize) => {
    resizeCanvas(ctx);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // 리사이즈 이후에 해야함.
    ctx.imageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;

    // Resize 여부에 따라 변경이 필요한 컴포넌트에 전달
    onResize();
};

const frameRequestCallback = (ctx) => (time) => {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    return renderer(ctx, time, resize);
};

const getResize = () => hasResized;
const onResize = () => (hasResized = true);
const offResize = () => (hasResized = false);
const resize = {
    getResize,
    offResize,
};
const onMouseMove = (e) => {
    setMouse(e.clientX, e.clientY);
};

let hasResized = false;
let isRendering = false;
let quitOldRenderer = false;
let oldTime = 0;
let components = [];

const setComponents = (cmps) => {
    components = cmps;
};

const renderer = (ctx, time, resize) => {
    const delta = (time - oldTime) / 1000;
    const hasResized = resize.getResize();
    if (hasResized) console.log('resized');

    for (const key in components) {
        components[key](ctx, time, delta, hasResized);
    }

    if (quitOldRenderer) {
        quitOldRenderer = false;
        return;
    }

    oldTime = time;
    resize.offResize();
    requestAnimationFrame(frameRequestCallback(ctx));
};

const Canvas = ({ components }) => {
    useEffect(() => {
        setComponents(components);

        window.addEventListener('resize', () => initializeCanvas(ctx));
        window.addEventListener('mousemove', onMouseMove);

        const c = document.querySelector('canvas');
        const ctx = c.getContext('2d');

        initializeCanvas(ctx);

        if (isRendering) quitOldRenderer = true;
        isRendering = true;
        requestAnimationFrame(frameRequestCallback(ctx));
    }, []);

    return <canvas></canvas>;
};

export default Canvas;
