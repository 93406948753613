import './style.sass';
import Canvas from '../../component/Canvas';
import portrait from '../../static/images/portrait_animation.gif';
import Ground from '../../component/Canvas/game/ground';

const Profile = (event, state) => {
    return (
        <div className='comp__common'>
            <Canvas components={[Ground]}></Canvas>
            <section className='sect__profile'>
                <img
                    className='sect__profile--portrait'
                    src={portrait}
                    alt='portrait'
                />
                <br></br>
                <div className='sect__profile--title'>
                    <h3>KangHyun Lee</h3>
                </div>
                <br></br>
                <div>
                    <p className='sect__profile--caption'>A.K.A DORAGE</p>
                </div>
            </section>
            <section></section>
        </div>
    );
};

export default Profile;
