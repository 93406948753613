const pink = '#BF045B';
const blue = `rgba(255,255,255, 0.1)`;
const white = 'white';
const black = 'black';

export const cvVars = {
    color: {
        ground: pink,
        star: blue,
        starship: white,
        mail: white,
        black: black,
    },
};
