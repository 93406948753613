import {
    clamp,
    ctxHOC,
    drawFillRect,
    drawLine,
    drawStrokeRect,
    getHV,
    random,
    randomRate,
} from './utils';
import { cvVars } from './utils/static';

const drawMail = (ctx, x, y, size, opacity = 1) => {
    const width = size;
    const height = (2 * width) / 3;

    ctx.globalAlpha = opacity;
    ctx.fillStyle = cvVars.color.black;
    ctx.strokeStyle = cvVars.color.mail;

    drawFillRect(ctx, x, y, width, height, cvVars.color.black);
    drawStrokeRect(ctx, x, y, width, height, cvVars.color.mail);

    drawLine(
        ctx,
        x,
        y + height / 5,
        x + width / 2,
        y + (3 * height) / 5,
        1,
        'white'
    );
    drawLine(
        ctx,
        x + width,
        y + height / 5,
        x + width / 2,
        y + (3 * height) / 5,
        1,
        'white'
    );
};

const randomMails = (ctx, duration, time) => {
    const [H, V] = getHV();
    const rate = (time / duration) % 1;

    for (const { x, size, distance, defaultRate } of mails) {
        const currRate = (rate + defaultRate) % 1;
        const currY = distance * currRate - size;
        drawMail(
            ctx,
            x,
            V - currY,
            size,
            currRate > 0.8 ? (1 - currRate) * 5 : 1
        );
    }
};

const mailMap = () => {
    const [H, V] = getHV();
    const results = [];

    for (let i = 0; i < random(150, 200); i++) {
        const size = random(50, 100);
        const x = random(-size, H + size);
        const distance = random(3 * size, V * 0.6);
        const defaultRate = randomRate(100);
        results.push({ x, size, distance, defaultRate });
    }

    return results;
};

let mails = mailMap();

export const Mail = (ctx, ms, delta, resize) => {
    if (resize) mails = mailMap();
    ctx.shadowBlur = 5;
    ctx.shadowColor = cvVars.color.starship;
    randomMails(ctx, 2000, ms);
};

export default ctxHOC(Mail);
