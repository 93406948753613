const Project = ({ data }) => {
    const { id, name, year, stacks } = data;

    return (
        <a
            className='project__div'
            href={`https://www.notion.so/${id.replaceAll('-', '')}`}
            target='_blank'
            rel='noreferrer'
        >
            <div>
                <div className='project__div--year'>{year}</div>
                <div className='project__div--title'>
                    <h6>{name}</h6>
                </div>
                <div
                    className='project__div--thumbnail'
                    data-src={`/images/portfolio/${id}.jpg`}
                ></div>
                <div className='project__div--tags'>
                    {stacks.map((e) => (
                        <div className='tags__tag' key={e}>
                            {e}
                        </div>
                    ))}
                </div>
            </div>
        </a>
    );
};

export default Project;
