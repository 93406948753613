import { clamp, ctxHOC, random } from './utils';

const drawStar = (ctx, x, y, scale = 1, alpha = 0.1) => {
    ctx.save();
    ctx.fillStyle = `rgba(255,255,255,${alpha})`;
    const w = 12 * scale;
    const h = 3 * scale;
    ctx.shadowBlur = 10;
    ctx.shadowColor = 'blue';
    ctx.fillRect(x - w / 2, y - h / 2, w, h);
    ctx.fillRect(x - h / 2, y - w / 2, h, w);
    ctx.restore();
};

const getStarMap = (minStar = 80, maxStar = 150) => {
    const v = window.innerHeight;
    const h = window.innerWidth;
    const len = Math.floor(random(minStar, maxStar));
    const result = Array(len)
        .fill(0)
        .map(() => ({}));

    let i = -1;
    while (++i < len) {
        result[i].scale = random(0.2, 1);
        result[i].x = Math.floor(random(0, h));
        result[i].y = Math.floor(random(0, v / 2 - 50));
    }
    return result;
};

let stars = getStarMap();

export const lightUpStars = (ctx, duration, time, resize) => {
    if (resize) {
        stars = getStarMap();
    }
    const rate = time / duration;
    stars.forEach(({ x, y, scale }) => drawStar(ctx, x, y, scale * rate, rate));
};

export const blinkStars = (ctx, duration, time, resize) => {
    if (resize) {
        stars = getStarMap();
    }

    const rate = Math.abs(((time / duration / 2) % 1) * 2 - 1);
    stars.forEach(({ x, y, scale }) =>
        drawStar(
            ctx,
            x,
            y,
            scale,
            clamp(rate, 0.2, 1)
            //clamp(Math.abs(((Math.floor(time / 5) % 200) - 100) / 100), 0.5, 1) // 2초 단위로 깜빡이게 하기
        )
    );
};
