import Project from './Project';
import portfolioData from '../../config/portfolioData';
import react, { useEffect, useState } from 'react';
import './style.sass';

/*
TODO;
화면 중앙에 하나 양옆에 하나씩 (첫번째, 마지막 비연결)
게임 선택화면처럼 만들기 ( 뒷쪽 체크무늬 지나가게끔 왼쪽 아래방향으로 내려가기 )
*/

const years = ['All', ...new Set(portfolioData.map((e) => e.year))];

const Projects = () => {
    const [selectedYear, setYear] = useState('All');
    const [portfolios, setPortfolios] = useState(portfolioData);
    const [pagination, setPagination] = useState(0);

    const onClickYear = (year) => {
        setYear(year);
        setPagination(0);
    };

    const onClickNext = () => {
        setPagination(pagination >= portfolios.length - 1 ? 0 : pagination + 1);
    };
    const onClickPrev = () => {
        setPagination(pagination <= 0 ? portfolios.length - 1 : pagination - 1);
    };

    useEffect(() => {
        if (selectedYear === 'All') {
            setPortfolios(portfolioData);
            return;
        }
        setPortfolios(portfolioData.filter((e) => e.year === selectedYear));
    }, [selectedYear]);

    return (
        <div className='comp__common'>
            <div className='project__background'></div>
            <section className='project__section'>
                <div className='project__section--year'>
                    {years.map((year) =>
                        selectedYear === year ? (
                            <div class='project__button--year project__button--year-selected'>
                                {year}
                            </div>
                        ) : (
                            <div
                                className='project__button--year'
                                onClick={() => onClickYear(year)}
                            >
                                {year}
                            </div>
                        )
                    )}
                </div>
                <div className='project__section--slider'>
                    <div className='slider__side  slider__side--prev'>
                        {portfolios.length > 1 && (
                            <button
                                className='slider__side--button'
                                onClick={onClickPrev}
                            >
                                {'<'}
                            </button>
                        )}
                    </div>
                    <div className='slider__center'>
                        <Project
                            key={portfolios[pagination].id}
                            data={portfolios[pagination]}
                        ></Project>
                    </div>
                    <div className='slider__side'>
                        {portfolios.length > 1 && (
                            <button
                                className='slider__side--button'
                                onClick={onClickNext}
                            >
                                {'>'}
                            </button>
                        )}
                    </div>
                </div>
                <div className='slider__bottom'>
                    {portfolios.map((_, id) => (
                        <div
                            className={
                                id === pagination
                                    ? 'anim-glitch slider__indicator slider__indicator--selected'
                                    : 'anim-glitch slider__indicator'
                            }
                        ></div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Projects;
